// import logo from './logo.svg';
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Preferences from "./components/Preferences/Preferences";
import Login from "./components/Login/Login";
// import { userAccount } from "./constants.json";
import "./App.css";

const VERSION = "v3.0.6";
const DEFAULT_ACCOUNT = "abra";

function setCookie(a, b, c, dom) {
    var d = new Date();
    d.setDate(d.getDate() + c);
    var e = escape(b) + (c == null ? "" : ";expires=" + d.toUTCString());
    var domain = dom != null ? ";domain=" + dom : "";
    document.cookie = a + "=" + e + domain;
}

function deleteCookie(name, path, domain) {
    document.cookie =
        name +
        "=" +
        (path ? ";path=" + path : "") +
        (domain ? ";domain=" + domain : "") +
        ";expires=Thu, 01-Jan-1970 00:00:01 GMT";
}

function getUrlVal(str) {
    var v = window.location.search.match(
        new RegExp("(?:[?&]" + str + "=)([^&]+)")
    );
    return v ? v[1] : null;
}

function App() {
    const [userAccount, setUserAccount] = useState(DEFAULT_ACCOUNT);

    const resolveLogin = (loginInfo) => {
        const { token = "" } = loginInfo;
        setCookie("token", token, null, "spottertech.com");

        let tokenUrl = "https://v3.spottertech.com";

        let origin = getUrlVal("spotterOrigin");
        let unsecure = getUrlVal("spotterOriginDev");
        let port = getUrlVal("spotterOriginPort");
        if (origin) {
            let protocol = unsecure ? "http://" : "https://";
            tokenUrl = protocol + origin + ".spottertech.com";
            if (port) tokenUrl += ":" + port;
        }
        document.location = tokenUrl;
        // console.log(`set location ${tokenUrl}`);
        setTimeout(() => {
            deleteCookie("token", "/", "spottertech.com");
        }, 5000);
    };

    useEffect(() => {
        let account = getUrlVal("account");
        if (account) {
            setUserAccount(account);
        }
    }, []);

    return (
        <div className="outer">
            <div className="middle">
                <div className="inner">
                    <img
                        src="/images/spotter.png"
                        alt="Spotter v3"
                        title={VERSION}
                    />
                    <Login
                        userAccount={userAccount}
                        resolveLogin={resolveLogin}
                    ></Login>
                </div>
            </div>
        </div>
    );
}

export default App;
