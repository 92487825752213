import React, { useEffect, useState } from "react";
import "./Login.css";

const LOGIN_URL_PWD = "https://api.spottertech.com/login/login";
const LOGIN_URL_3P = "https://api.spottertech.com/login";
const LOGIN_URL_GOOGLE = "https://api.spottertech.com/login/googleLogin";
const debug = true;

async function loginUser(url, credentials) {
    return fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
    })
        .then((response) => {
            if (!response.ok) {
                console.log("login response not ok");
                throw Error(response.statusText);
            }
            return response;
        })
        .then((data) => data.json())
        .catch((error) => {
            console.log("login catch error");
            console.log(error);
            return { status: 102 };
        });
}

const Login = ({ resolveLogin, userAccount }) => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [googleReady, setGoogleReady] = useState(false);
    const [busy, setBusy] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const attemptLogin = async (url, credentials) => {
        setErrorMsg("");
        const loginInfo = await loginUser(url, credentials);
        const { status, message = "" } = loginInfo || {};
        if (status === 0) {
            setBusy(false);
            resolveLogin(loginInfo);
        } else {
            let msg = "";
            switch (`${status}`) {
                case "101":
                    msg = "One or more servers are not available";
                    break;
                case "102":
                    msg = "Server error, please try again";
                    break;
                case "103":
                    msg = "Error connecting to database.";
                    break;
                case "104":
                    msg = "Database query error";
                    break;
                case "105":
                    msg = "Query results empty error";
                    break;
                case "106":
                    msg =
                        "Username and/or password incorrect, please try again!";
                    break;
                case "109":
                    msg = "User validation failed";
                    break;
                case "110":
                    msg = "General error, resource not found";
                    break;
                case "201":
                    msg =
                        "User/password login disabled, please use Google button";
                    break;
                case "999":
                    msg = message;
                    break;
                default:
                    msg = "Unknown error";
            }

            setBusy(false);
            setErrorMsg(msg);
        }
    };

    const connectNow = async (idToken) => {
        const credentials = { token: idToken, userAgent: navigator.userAgent };
        attemptLogin(LOGIN_URL_GOOGLE, credentials);
    };

    const onSignIn = (googleUser) => {
        setBusy(true);
        const idToken = googleUser.getAuthResponse().id_token;
        const auth2 = window.gapi.auth2.getAuthInstance();
        auth2.signOut().then(() => {
            // this is so onSignIn isn't called automatically when the page is loaded,
            // in case the user is already signed in with one google account but wants to log in to spotter with a different account
            if (debug) console.log("signed out");
        });
        // grab email and pass to api-login, get token
        connectNow(idToken);
    };

    const handleSubmit = (e) => {
        // todo: pick url by account
        setBusy(true);
        e.preventDefault();
        const loginUrl = `${LOGIN_URL_3P}/${userAccount}`;
        attemptLogin(loginUrl, {
            username,
            password,
        });
    };

    useEffect(() => {
        const googleButtonSettings = {
            scope: "profile email",
            width: 240,
            height: 50,
            longtitle: true,
            theme: "dark",
            onsuccess: onSignIn,
        };
        setTimeout(() => {
            window.gapi.signin2.render("my-signin2", googleButtonSettings);
            setGoogleReady(true);
        }, 100);
    }, []);

    const hostName = window.location.hostname;
    const thisYear = new Date().getFullYear();

    return (
        <div className="login-wrapper">
            <div className="sign-in-box box shadow bg-1">
                {userAccount === "abra" ? (
                    <div
                        className="box"
                        style={{ display: googleReady ? "flex" : "none" }}
                    >
                        <div
                            id="my-signin2"
                            style={{ marginTop: "15px", marginBottom: "15px" }}
                        ></div>
                    </div>
                ) : (
                    <div className="box">
                        <form onSubmit={handleSubmit}>
                            <table className="login-input-table">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "left" }}></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "right" }}>
                                            Email
                                        </td>
                                        <td>
                                            {" "}
                                            <input
                                                type="text"
                                                onChange={(e) =>
                                                    setUsername(e.target.value)
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "right" }}>
                                            Password
                                        </td>
                                        <td>
                                            <input
                                                type="password"
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ textAlign: "left" }}>
                                            <button
                                                type="submit"
                                                disabled={busy}
                                            >
                                                Log In
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                )}
                <div className="footer">
                    {userAccount == "abra" ? (
                        <span>{hostName}</span>
                    ) : (
                        <span>
                            Support:
                            <a href="mailto:support@abracontrols.com">
                                support@abracontrols.com
                            </a>
                        </span>
                    )}{" "}
                    | Copyright 2010 - {thisYear} &copy; Abra Controls Inc.
                </div>
            </div>
            <div className="error-message">{errorMsg}</div>
        </div>
    );
};

export default Login;
